import React from "react";
// import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import { Icon } from "semantic-ui-react";

const DownloadCSV = ({ data, fileName }) => {
  const convertToCSV = (objArray) => {
    let allData = [];

    objArray.forEach((element) => {
      if (element.data && Array.isArray(element.data)) {
        if (allData.length === 0 && element.data.length > 0) {
          // Add headers as the first row
          allData.push(Object.keys(element.data[0]));
        }
        allData = allData.concat(element.data);
      }
    });

    let str = "\ufeff"; // Add BOM for UTF-8 encoding
    for (let i = 0; i < allData.length; i++) {
      let line = "";
      for (let index in allData[i]) {
        if (line !== "") line += ",";
        // Escape special characters and wrap in quotes if needed
        let value = allData[i][index];
        if (
          typeof value === "string" &&
          (value.includes(",") || value.includes('"') || value.includes("\n"))
        ) {
          value = '"' + value.replace(/"/g, '""') + '"';
        }
        line += value;
      }
      str += line + "\r\n";
    }
    return str;
  };

  const downloadCSV = () => {
    // Use UTF-8 encoding for Chinese characters
    const csvData = new Blob([convertToCSV(data)], {
      type: "text/csv;charset=utf-8",
    });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement("a");
    link.href = csvURL;
    link.download = `${fileName}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(csvURL); // Clean up
  };

  return (
    <button onClick={downloadCSV}>
      <Icon name="download" />
      下載Ticker
    </button>
  );
};

export default DownloadCSV;
